import styled from '@emotion/styled';
// use in table
export const TableWithFilterContainer = styled.div `
  display: flex;
  flex-direction: column;
  height: calc(100% - 74px);
  background-color: #ffffff;
  border-radius: 14px;
`;
export const TableContainer = styled.div `
  flex: 1;
  background: #ffffff;
  cursor: pointer;
  padding: 0 12px;
`;
export const TableWithPaginationContainer = styled.div `
  flex: 1;
  background: #ffffff;
  cursor: pointer;
  padding: 0 12px;
  border-radius: 0 0 14px 14px;
`;
export const TableFooterContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: end;
  height: 52px;
  padding: 12px;
  border-top: 1px solid #e8e8e8;
`;
export const TableFlexCenterContainer = styled.div `
  .dui-table {
    color: #3d3d3d;
  }
  // .dui-table-tbody .dui-table-cell {
  //   display: flex;
  //   justify-content: left;
  //   align-items: center;
  // }
`;
export const TableFirstCellContainer = styled.div `
  display: flex;
  align-items: center;
  width: 100%;
`;
export const TableCellNameContainer = styled.div `
  padding-left: 0.75rem;
  width: calc(100% - 42px);
`;
