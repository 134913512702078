import React from 'react';
import { BackgroudWithWhiteContainer, PageOverview } from '@component/Style';

const Component = () => {
  return (
    <PageOverview>
      <BackgroudWithWhiteContainer style={{ padding: '20px' }}>
        <h1>Search Platform Web Portal</h1>
        <p>
          The search platform web portal is used for the management of
          search-platform resources and contains both search(online search) and
          puxian(offline data sync)
        </p>
        <br />
        <p>
          <b>What is the scheduled job?</b>
          <br />A scheduled job is a scheduled task run in puxian instance,
          usually, designed to do some task scheduled, for example, data full
          sync, auto-check(validate), and some monitor
        </p>
        <p>
          <b>What is the stream?</b>
          <br />
          Stream is usually used for real-time or asynchronous data processing,
          for example, the Mysql binlog stream
        </p>
        <p>
          <b>What is the script?</b>
          <br />
          The script is designed to execute scripts, for example, check data of
          Redis(getIntent stored in Redis)
        </p>
        <p>
          <b>How to request access?</b>
          <br />
          We are using Concedo for RBAC management, you can apply for permission
          by{' '}
          <a href={window.Grab.config.concedo.url} target="_black">
            Concedo
          </a>
          (App name: search-platform)
          <br />
          Different team members should apply for different roles; if you need
          all permissions, should apply for the super role
        </p>
      </BackgroudWithWhiteContainer>
    </PageOverview>
  );
};

export default Component;
