import styled from '@emotion/styled';
export const FiltersTopContainer = styled.div `
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0.5rem 0.5rem 0 0;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1rem;
`;
export const ButtonRightContainer = styled.div `
  align-items: center;
  display: inline-grid;
  flex-shrink: 0;
  gap: 16px;
  grid-auto-flow: column;
`;
