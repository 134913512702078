import styled from '@emotion/styled';
import { css } from '@emotion/react';
export const PageOverview = styled.div `
  padding: 12px;
  height: 100%;
`;
export const ContentWithBorderContainer = styled.div `
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  padding: 16px;
`;
export const MarginContainer = styled.div `
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
  margin-top: ${(props) => (props.mb ? props.mt : '0px')};
  margin-left: ${(props) => (props.mb ? props.lt : '0px')};
  margin-right: ${(props) => (props.mb ? props.rt : '0px')};
`;
export const TextOverFlowContainer = css `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const TextFontSize14Container = css `
  font-size: 14px;
  line-height: 16px;
`;
export const TextFontSize12Container = css `
  font-size: 12px;
  line-height: 14px;
`;
export const TextFontSize10Container = css `
  font-size: 10px;
  line-height: 12px;
`;
export const BackgroudWithWhiteContainer = styled.div `
  background: #fff;
  padding: 10px;
  border-radius: 14px;
  min-height: 100%;
`;
export const Title = styled.div `
  font-size: 14px;
  font-weight: 600;
`;
export const Subtitle = styled.div `
  color: #707070;
  font-size: 12px;
`;
export const DetailTitleWrapper = styled.div `
  color: #161a1d;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 20px;
`;
export const TitleWrapper = styled.div `
  color: #161a1d;
  font-size: 14px;
  font-weight: 600;
`;
export const DelIconWrapper = styled.span `
  color: red;
  margin-left: 4px;
  display: inline-block;
  cursor: pointer;
`;
