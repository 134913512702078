import styled from '@emotion/styled';
export const JsonViewContainer = styled.div `
  width: 100%;
  // .ace_editor .ace_print-margin {
  //   visibility: hidden;
  // }
  .dark {
    background: #3d3d3d;
    border-radius: 0.75rem;
    padding: 1rem;
  }

  .label,
  .string,
  .number,
  .boolean,
  .null,
  .punctuation,
  .undefined {
    color: #fff;
    font-family: 'Roboto Mono', monospace;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.005rem;
    line-height: 24px;
    margin-right: 2px;
  }

  .label,
  .string,
  .punctuation {
    color: #f7f7f7;
  }

  .number {
    color: #4ec9b0;
  }

  .boolean {
    color: #4fc1ff;
  }

  .null {
    color: #f44747;
  }

  .undefined {
    color: #b267e6;
  }

  .back-button {
    margin-left: 0.375rem;
  }

  .back-button:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;
