import React, { StrictMode, lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  Navigate,
  Outlet,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Layout, ConfigProvider as DuxtonProvider } from '@duxton-web/ui';
import { ROUTES } from './Routes';
import AppSider from './AppSider';
import { isAuthenticated } from '../sessions/concedoLogin/helper';
import LazyWithErrorBoundary from './LazyWithErrorBoundary';
import themeConfig from '../../theme/index';

const Login = lazy(() => import('../sessions/LoginPage'));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 60000 /* Data is consider stale after 1 minute */,
      refetchOnWindowFocus: false,
    },
  },
});

export const ApiProvider = () => {
  const location = useLocation();

  if (!isAuthenticated()) {
    const to = { pathname: '/login' };

    if (location) {
      to.state = { from: location };
    }

    return <Navigate to={to} />;
  }
  return (
    <Layout style={{ height: '100vh' }}>
      <AppSider />
      <Layout
        style={{
          height: '100vh',
        }}
      >
        <Layout.Content
          style={{
            background: '#F5F5F5',
            // padding: '16px',
            overflowY: 'scroll',
            height: '100%',
          }}
        >
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

const router = createBrowserRouter([
  {
    element: <ApiProvider />,
    children: ROUTES,
  },
  {
    path: '/login',
    element: LazyWithErrorBoundary(<Login />),
  },
]);

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <StrictMode>
        <DuxtonProvider theme={themeConfig}>
          <Suspense fallback={<div>Loading...</div>}>
            <RouterProvider router={router} />
          </Suspense>
        </DuxtonProvider>
      </StrictMode>
    </QueryClientProvider>
  );
};

export default App;
