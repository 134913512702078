import { Row } from '@duxton-web/ui';
import styled from '@emotion/styled';
export const JobNameWrapper = styled.div `
  color: #161a1d;
  font-size: 14px;
  font-weight: 600;
`;
export const JobHeaderWrapper = styled.div `
  // align-items: center;
  background: white;
  border-bottom: 1px solid #0505050f;
  // display: flex;
  gap: 8px;
  padding: 12px 32px 0px;
  position: sticky;
  top: 0;
  z-index: 3;
`;
export const JobOverview = styled.div `
  display: flex;
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  min-width: 200px;
`;
export const JobContentWrapper = styled.div `
  align-items: center;
  background-color: white;
  border-radius: 14px;
  border: none;
  height: '100%';
  padding: 20px;
  margin-bottom: 14px;
`;
export const JobScrollContentWrapper = styled.div `
  overflow-y: scroll;
  height: 100%;
  padding: 24px;
`;
export const JobDashBoardBox = styled(Row) `
  display: flex;
  align-items: flex-start;
  padding: 12px;
  border-radius: 14px;
  height: 140px;
  border: 1px solid #eee;
  width: 100%;
`;
